<template>
  <div>
    <b-modal id="modal-referral-bonus-withdrawal" :cancel-title="$t('modal.cancel')"
             centered
             :ok-title="$t('modal.ref.ok')"
             :title="$t('modal.ref.title')"
             @hidden="resetForm"
             @ok="handleOk"
             @reset="resetForm"
             @show="resetForm"
    >
      <b-alert
          :show="alertCountdown"
          :variant="alertVariant"
          dismissible
          @dismissed="alertCountdown=0"
      >
        {{ alertText }}
      </b-alert>

      <b-form-group :label="$t('modal.ref.payment_system')">
        <b-form-radio v-model="paymentSystem" value="card">{{ $t('modal.ref.card') }}</b-form-radio>
        <b-form-radio v-model="paymentSystem" value="qiwi">QIWI</b-form-radio>
      </b-form-group>

      <b-form>
        <b-form-group>
          <b-form-input
              id="requisites"
              v-model.trim.lazy="requisite"
              :placeholder="paymentSystem === 'card' ? $t('modal.ref.card_number') : $t('modal.ref.phone_number')"
              required
              type="requisites"
          ></b-form-input>
        </b-form-group>

      </b-form>
    </b-modal>
  </div>
</template>

<script>

import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'modal-referral-bonus-withdrawal',

  data() {
    return {
      paymentSystem: 'card',
      requisite: '',


      alertCountdown: 0,
      alertText: '',
      alertVariant: 'danger'
    }
  },

  computed: {
    ...mapGetters(['token']),
  },

  methods: {
    handleOk: function (bvModalEvt) {

      bvModalEvt.preventDefault()

      let reqBody = {
        paymentSystem: this.paymentSystem,
        requisite: this.requisite
      };

      let that = this

      axios.post('/api/profile/referral/withdraw', reqBody,
          {
            headers: {
              'Authorization': 'Bearer ' + this.token
            }
          }
      ).then(
          (response) => {
            if (response.data.result === true) {
              that.alertText = response.data.message
              that.alertCountdown = 10
              that.alertVariant = "success"

              this.$nextTick(
                  setTimeout(() => {
                    this.$bvModal.hide('modal-referral-bonus-withdrawal')
                    this.$router.go();
                  }, 1000))
            }
          }
      ).catch(function (error) {
        if (error.response) {
          that.alertText = error.response.data.message
          that.alertCountdown = 30
        }
      });
    },

    resetForm: function () {
      this.paymentSystem = ''
      this.requisite = ''
      this.alertText = ''
      this.alertCountdown = 0
    }
  }
}
</script>

