<template>
  <div class="right_side">

    <div class="section_payment_breadcrumbs">
      <span>{{ $t('profile.main_page') }}</span>
      <span>{{ $t('profile.request_history.requests') }}</span>
    </div>

    <div class="section_header mt-3">
      <span>{{ $t('profile.request_history.requests') }}</span>
    </div>

    <div class="mt-4" v-if="history == null">
      <span>{{ $t('profile.request_history.no_requests') }}</span>
    </div>

    <div v-else>
      <br>
      <BPagination
          align="center"
          v-model="page"
          :total-rows="history.totalElements"
          :per-page="history.size"
          @change="changePage"
      />
      <br>

      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">{{ $t('profile.request_history.request') }}</th>
          <th scope="col">{{ $t('profile.request_history.status') }}</th>
          <th scope="col">{{ $t('profile.request_history.direction') }}</th>
          <th scope="col">{{ $t('profile.request_history.amount') }}</th>
          <th scope="col">{{ $t('profile.request_history.date') }}</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="req in history.content" v-bind:key="req.id">
          <td>
            <router-link :to="{ name: 'Payment', params: { uuid: req.uuid }}">№ {{ req.id }}</router-link>
          </td>
          <td>
            <span v-if="req.status === 'NEW'" class="badge badge-pill badge-primary">{{ $t('profile.request_history.status_new') }}</span>
            <span v-if="req.status === 'FOR_PAYMENT'" class="badge badge-pill badge-secondary">{{ $t('profile.request_history.status_for_payment') }}</span>
            <span v-else-if="req.status === 'PAYED'" class="badge badge-pill badge-info">{{ $t('profile.request_history.status_payed') }}</span>
            <span v-else-if="req.status === 'FOR_TRANSFER'"
                  class="badge badge-pill badge-warning">{{ $t('profile.request_history.status_for_transfer') }}</span>
            <span v-else-if="req.status === 'DONE'" class="badge badge-pill badge-success">{{ $t('profile.request_history.status_done') }}</span>
            <span v-else-if="req.status === 'ERROR'" class="badge badge-pill badge-danger">{{ $t('profile.request_history.status_error') }}</span>
            <span v-else-if="req.status === 'EXPIRED'" class="badge badge-pill badge-light">{{ $t('profile.request_history.status_expired') }}</span>
          </td>
          <td>{{ req.srcPaySystem.name }} - {{ req.dstPaySystem.name }}</td>
          <td>{{ req.srcAmount }} {{ req.srcPaySystem.currency.isoCode }} - {{ req.dstAmount }}
            {{ req.dstPaySystem.currency.isoCode }}
          </td>
          <td>{{ req.createdDate | moment("DD.MM.YYYY") }}</td>
          <td>
            <router-link :to="{ name: 'Payment', params: { uuid: req.uuid }}">{{ $t('profile.request_history.view') }}</router-link>
          </td>
        </tr>
        </tbody>
      </table>

      <br>
      <BPagination
          align="center"
          v-model="page"
          :total-rows="history.totalElements"
          :per-page="history.size"
          @change="changePage"
      />
      <br>
    </div>

  </div>
</template>

<script>

import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'RequestHistory',
  components: {},

  data: function () {
    return {
      page: 1,
      history: {}
    }
  },

  computed: {
    ...mapGetters(['token']),
  },

  methods: {
    fetch: function (page) {
      axios.get('/api/profile/history?&page=' + page, {
        headers: {
          'Authorization': 'Bearer ' + this.token
        }
      }).then(
          response => {
            this.history = response.data
          }
      );
    },
    changePage: async function (newPage) {
      this.page = newPage;
      this.fetch(this.page);
    },
  },

  mounted() {
    this.fetch(this.page)
  }
}
</script>