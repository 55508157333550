<template>
  <section class="mt-4 pb-5">
    <div class="container">
      <div class="inner_page_two_sides">
        <div class="left_side max-width-300 d-none d-sm-none d-md-block">
          <div id="accordion" class="panel-group">
            <div class="panel panel-default">
              <div class="panel-heading active">
                <h4 class="panel-title" data-parent="#accordion" data-toggle="collapse" href="#collapseOne"
                    @click="function() {
                          historyType = 'active'
                          activeComponent = 'requests'
                        }">
                      <span class="icon">
                        <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 10H2V8H0V10ZM0 14H2V12H0V14ZM0 6H2V4H0V6ZM4 10H18V8H4V10ZM4 14H18V12H4V14ZM4 4V6H18V4H4Z"
                            fill="#0963B7"></path>
                        </svg>
                      </span>
                  <span class="title">{{ $t('common.requests') }}</span>
                </h4>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 v-b-toggle.collapse-1 class="panel-title" data-parent="#accordion" data-toggle="collapse"
                    href="#collapseTwo">
                    <span class="icon">
                      <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                      <path
                          d="M12.0227 8.25C13.3809 8.25 14.4691 7.245 14.4691 6C14.4691 4.755 13.3809 3.75 12.0227 3.75C10.6645 3.75 9.56818 4.755 9.56818 6C9.56818 7.245 10.6645 8.25 12.0227 8.25ZM5.47727 8.25C6.83545 8.25 7.92364 7.245 7.92364 6C7.92364 4.755 6.83545 3.75 5.47727 3.75C4.11909 3.75 3.02273 4.755 3.02273 6C3.02273 7.245 4.11909 8.25 5.47727 8.25ZM5.47727 9.75C3.57091 9.75 -0.25 10.6275 -0.25 12.375V14.25H11.2045V12.375C11.2045 10.6275 7.38364 9.75 5.47727 9.75ZM12.0227 9.75C11.7855 9.75 11.5155 9.765 11.2291 9.7875C12.1782 10.4175 12.8409 11.265 12.8409 12.375V14.25H17.75V12.375C17.75 10.6275 13.9291 9.75 12.0227 9.75Z"
                          fill="black"></path>
                      </g>
                      <defs>
                      <clipPath id="clip0">
                      <rect fill="white" height="18" width="18"></rect>
                      </clipPath>
                      </defs>
                      </svg>

                    </span>
                  <span class="title">{{ $t('profile.partnership_program') }}</span>
                </h4>
              </div>
              <div id="collapseTwo" class="panel-collapse">
                <div class="panel-body">
                  <table class="table">
                    <tr>
                      <td>
                        <a href="#" @click="activeComponent = 'referral_info'">{{ $t('profile.info') }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href="#" @click="activeComponent = 'referral_history'">{{ $t('profile.charges') }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href="#" @click="activeComponent = 'referral_withdrawals'">{{ $t('profile.payments') }}</a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="right_side">
          <hr style="margin-bottom: 10px"/>

          <div id="accordion" class="panel-group d-block d-sm-block d-md-none">
            <div class="panel panel-default">
              <div class="panel-heading active">
                <h4 class="panel-title" data-parent="#accordion" data-toggle="collapse" href="#collapseOne"
                    @click="function() {
                          historyType = 'active'
                          activeComponent = 'requests'
                        }">
                      <span class="icon">
                        <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 10H2V8H0V10ZM0 14H2V12H0V14ZM0 6H2V4H0V6ZM4 10H18V8H4V10ZM4 14H18V12H4V14ZM4 4V6H18V4H4Z"
                            fill="#0963B7"></path>
                        </svg>
                      </span>
                  <span class="title">{{ $t('common.requests') }}</span>
                </h4>
              </div>
            </div>
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 v-b-toggle.collapse-1 class="panel-title" data-parent="#accordion" data-toggle="collapse"
                    href="#collapseTwo">
                    <span class="icon">
                      <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                      <path
                          d="M12.0227 8.25C13.3809 8.25 14.4691 7.245 14.4691 6C14.4691 4.755 13.3809 3.75 12.0227 3.75C10.6645 3.75 9.56818 4.755 9.56818 6C9.56818 7.245 10.6645 8.25 12.0227 8.25ZM5.47727 8.25C6.83545 8.25 7.92364 7.245 7.92364 6C7.92364 4.755 6.83545 3.75 5.47727 3.75C4.11909 3.75 3.02273 4.755 3.02273 6C3.02273 7.245 4.11909 8.25 5.47727 8.25ZM5.47727 9.75C3.57091 9.75 -0.25 10.6275 -0.25 12.375V14.25H11.2045V12.375C11.2045 10.6275 7.38364 9.75 5.47727 9.75ZM12.0227 9.75C11.7855 9.75 11.5155 9.765 11.2291 9.7875C12.1782 10.4175 12.8409 11.265 12.8409 12.375V14.25H17.75V12.375C17.75 10.6275 13.9291 9.75 12.0227 9.75Z"
                          fill="black"></path>
                      </g>
                      <defs>
                      <clipPath id="clip0">
                      <rect fill="white" height="18" width="18"></rect>
                      </clipPath>
                      </defs>
                      </svg>

                    </span>
                  <span class="title">{{ $t('profile.partnership_program') }}</span>
                </h4>
              </div>
              <div id="collapseTwo" class="panel-collapse">
                <div class="panel-body">
                  <table class="table">
                    <tr>
                      <td>
                        <a href="#anchor" @click="activeComponent = 'referral_info'">{{ $t('profile.info') }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href="#anchor" @click="activeComponent = 'referral_history'">{{ $t('profile.charges') }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href="#anchor" @click="activeComponent = 'referral_withdrawals'">{{ $t('profile.payments') }}</a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div id="anchor">
            <component v-bind:is="component" v-bind="{ type: historyType}"></component>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import RequestHistory from "../components/profile/RequestHistory";
import ReferralInfo from "../components/profile/ReferralInfo";
import ReferralHistory from "../components/profile/ReferralHistory";
import axios from "axios";
import {mapGetters} from "vuex";

const REQUESTS_HISTORY = () => import("@/components/profile/RequestHistory")
const REFERRAL_INFO = () => import("@/components/profile/ReferralInfo")
const REFERRAL_HISTORY = () => import("@/components/profile/ReferralHistory")
const REFERRAL_WITHDRAWALS = () => import("@/components/profile/ReferralWithdrawals")

export default {
  name: 'Profile',

  components: {RequestHistory, ReferralInfo, ReferralHistory},

  data: function () {
    return {

      activeComponent: '',

      showRequestHistory: true,
      showReferralInfo: false,
      showReferralHistory: false,

      historyStats: {},

      historyType: 'active'


    }
  },

  computed: {
    ...mapGetters(['token']),

    component() {
      let result
      switch (this.activeComponent) {
        case "requests":
          result = REQUESTS_HISTORY;
          break
        case "referral_info":
          result = REFERRAL_INFO;
          break
        case "referral_history":
          result = REFERRAL_HISTORY;
          break
        case "referral_withdrawals":
          result = REFERRAL_WITHDRAWALS;
          break
        default:
          result = REQUESTS_HISTORY;
      }
      return result;
    }
  },

  mounted() {
    axios.get('/api/profile/history/stats', {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(
        response => {
          this.historyStats = response.data
        }
    );
  }
}
</script>
