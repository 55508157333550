<template>
  <div class="right_side">
    <div class="section_payment_breadcrumbs">
      <span>{{ $t('profile.main_page') }} / {{ $t('profile.partnership_program') }} - {{ $t('profile.ref_info.info') }}</span>
    </div>

    <div class="section_header mt-3 mb-3">
      <span>{{ $t('profile.partnership_program') }} - {{ $t('profile.ref_info.info') }}</span>
    </div>

    <div class="info_identificator_box">

      <div class="info_identificator_box_image">
        <img src="@/img/dollar.svg" alt="dollar">
      </div>

      <div class="info_identificator_box_title">{{ $t('profile.ref_info.partner_id') }} {{ uuid }}</div>
      <div class="info_identificator_box_form_wrapper mt-3">
        <div class="info_identificator_box_form">
          <input v-model="link" type="text">
          <button @click="copyLink">{{ $t('profile.ref_info.copy') }}</button>
        </div>
      </div>
      <div class="info_identificator_box_description">{{ $t('profile.ref_info.share') }}</div>
    </div>

    <div class="section_header_without_underline mt-3">
      <span>{{ $t('profile.ref_info.stats') }}</span>
    </div>

    <div class="row">
      <div class="col-sm">
        <div class="info_identificator_box_stat mb-2">
          <span>{{ info.referrals }}</span>
          <span>{{ $t('profile.ref_info.involved') }}</span>
        </div>
      </div>
      <div class="col-sm">
        <div class="info_identificator_box_stat mb-2">
          <span>{{ info.exchanges }}</span>
          <span>{{ $t('profile.ref_info.exchanges') }}</span>
        </div>
      </div>
      <div class="col-sm">
        <div class="info_identificator_box_stat mb-2">
          <span>$ {{ info.earnings }}</span>
          <span>{{ $t('profile.ref_info.exchanges_amount') }}</span>
        </div>
      </div>
    </div>

    <div v-if="info.balance < 5">
      <div class="alert alert-warning" role="alert">
        ⚠️ {{ $t('profile.ref_info.min_withdrawal') }} <strong>$ 5</strong>.
      </div>
    </div>
    <div class="info_identificator_box_balance mt-4">
      <div>{{ $t('profile.ref_info.balance') }} - <span>$ {{ info.balance }}</span></div>
      <div v-if="info.balance >= 5">
        <button v-b-modal.modal-referral-bonus-withdrawal>Вывести</button>
      </div>

    </div>

    <ReferralBonusWithdrawalForm/>

  </div>
</template>

<script>

import {mapGetters} from "vuex";
import axios from "axios";
import ReferralBonusWithdrawalForm from "@/components/modal/ReferralBonusWithdrawalForm";

export default {
  name: 'ReferralInfo',

  components: {
    ReferralBonusWithdrawalForm
  },

  data: function () {
    return {
      uuid: null,

      info: {
        referrals: 0,
        exchanges: 0,
        earnings: 0,
        balance: 0
      }
    }
  },

  computed: {
    ...mapGetters(['token']),
    link: function () {
      return 'https://moment.express/?ref=' + this.uuid
    }
  },

  methods: {
    async copyLink() {
      await navigator.clipboard.writeText(this.link);
    }
  },

  mounted() {
    axios.get('/api/auth/currentUser', {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(
        response => {
          this.uuid = response.data.uuid
        }
    );


    axios.get('/api/profile/referral', {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(
        response => {
          this.info = response.data
        }
    );
  }
}
</script>
