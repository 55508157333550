<template>
  <div class="right_side">

    <div class="section_payment_breadcrumbs">
      <span>{{ $t('profile.referral_history.main_page') }}</span>
      <span>{{ $t('profile.referral_history.partnership_program') }}</span>
    </div>

    <div class="section_header mt-3">
      <span>{{ $t('profile.referral_history.charges') }}</span>
    </div>

    <div class="mt-4" v-if="history == null">
      <span>{{ $t('profile.referral_history.no_charges') }}</span>
    </div>

    <table class="table table-striped" v-else>
      <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">{{ $t('profile.referral_history.direction') }}</th>
        <th scope="col">{{ $t('profile.referral_history.date') }}</th>
        <th scope="col">{{ $t('profile.referral_history.amount') }}</th>
        <th scope="col">{{ $t('profile.referral_history.status') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="r in history" v-bind:key="r.id">
        <td>{{ $t('profile.referral_history.exchange_request_number') }} {{ r.id }}</td>
        <td>{{ r.request.srcPaySystem.name }} - {{ r.request.dstPaySystem.name }}</td>
        <td>{{ r.createdDate | moment("DD.MM.YYYY") }}</td>
        <td>$ {{ r.value }}</td>
        <td>
          <span v-if="r.status === 'NEW'" class="badge badge-pill badge-primary">{{ $t('profile.referral_history.status_new') }}</span>
          <span v-if="r.status === 'ON_PAYMENT'" class="badge badge-pill badge-info">{{ $t('profile.referral_history.status_on_payment') }}</span>
          <span v-if="r.status === 'ERROR'" class="badge badge-pill badge-danger">{{ $t('profile.referral_history.status_error') }}</span>
          <span v-else-if="r.status === 'PAYED'" class="badge badge-pill badge-success">{{ $t('profile.referral_history.status_payed') }}</span>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>

<script>


import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'ReferralHistory',

  components: {},

  data: function () {
    return {

      history: null


    }
  },

  computed: {
    ...mapGetters(['token']),
  },

  mounted() {
    axios.get('/api/profile/referral/history', {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(
        response => {
          this.history = response.data
        }
    );
  }
}
</script>
